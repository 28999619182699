<template>
	<v-sheet class="accent">
		<div v-if="loading">
			<v-container class="mb-12 pb-12 accent">
				<v-layout flex align-center justify-center>
					<v-progress-circular indeterminate :size="250" :width="20" color="secondary" />
				</v-layout>
			</v-container>
		</div>
		<v-card dark color="secondary" elevation="0" v-if="!loading">
			<v-card-text>
				<GmapMap
					ref="loc"
					:center="center"
					:zoom="17"
					style="width: 100%; height: 450px"
					:options="{
						zoomControl: true,
						mapTypeControl: true,
						mapTypeId: 'hybrid',
						scaleControl: true,
						streetViewControl: false,
						rotateControl: false,
						fullscreenControl: true,
						disableDefaultUi: false,
					}"
				>
					<gmap-marker
					v-if="markers != null && markers[0] != null"
						:position="markers[0].position"
						:clickable="false"
						:draggable="false"
						:icon="{
							url: ('http://maps.google.com/mapfiles/ms/icons/green-dot.png'),
							scaledSize: {width: 50, height: 50, f: 'px', b: 'px'},
						}"
					/>

					<gmap-circle v-if="idata && idata.anchor && idata.visible" :center="center" :radius="idata.radio * 0.3048" :options="mapCenterOptions" />


				</GmapMap>
			</v-card-text>
			<v-card-actions>
				<span class="white--text secondary" v-text="`Current (lat/lon): ${lat},${lon} ` " />
				<span v-if="server" class="white--text secondary" v-text="`.  Server: ${server}` " />
				<v-spacer />

				<v-btn
					class="ml-4"
					small
					:to="{name: 'AdminLogTracking', params: { 'imei': imei } }"
				>
					Play Tracking
				</v-btn>

			</v-card-actions>
		</v-card>
</v-sheet>
</template>

<script>
	import { dbFb } from '@/main';
	export default {
		name: 'ShowGmap',
		props: {
			imei: String,
		},
		components: {
			MainLoaded: () => import('@/components/base/MainLoaded.vue'),
			ShowGmapLog: () => import('@/components/admin/ShowGmapLog.vue'),
		},
		mounted(){
			this.getData()
		},
		data: () => ({
			showActualPosition: true,
			showTracking: false,
			loading: true,
			center: {lat: 25.7383699, lng: -80.2021549 },
      lat: 0,
      lon: 0,
      markers: [],
			currentMidx: null,
			server: null,
			idata: null,
			mapCenterOptions: {
				strokeColor: '#FF0000',
				strokeOpacity: 0.8,
				strokeWeight: 3,
				fillColor: '#FF0000'
			}
		}),
		methods: {
			async getData(){
				this.loading = true
				try {
					const res = await this.getActualLocation()
				} catch (error) {
						console.log(error)
				} finally {
					this.loading = false
				}
			},
			async getActualLocation(){
				dbFb.ref(`/${this.imei}/data`).on("value", (snapshot) => {
					if (snapshot.val() != null){
						this.server = (snapshot.val().server != null) ? snapshot.val().server : null;
						this.lat = snapshot.val().lat / 10000000;
						this.lon = snapshot.val().lon / 10000000;
						this.timestamp = snapshot.val().timestamp
						this.center = {
							lat: parseFloat(this.lat),
							lng: parseFloat(this.lon)
						}
						this.markers = [];
						this.markers.push({
							position: {lat: parseFloat(this.lat), lng: parseFloat(this.lon) },
							timestamp: this.timestamp,
						},)
					} else {

						this.timestamp = new Date().getTime() / 1000
						this.markers.push({
							position: {lat: parseFloat(this.lat), lng: parseFloat(this.lon) },
							timestamp: this.timestamp,
						},)
						this.loading = false
					}
				});

				dbFb.ref(`/${this.imei}/idata/io0`).on("value", (snap) => {
					if (snap.val() != null) {
						this.idata = snap.val()
					}
				})


			},
		},
		computed: {
		}

	}
</script>

<style>
.v-select__slot {
		position: relative;
		align-items: center;
		display: flex;
		max-width: 100%;
		min-width: 0;
		width: 80px;
	}
	.lds-ripple {
  display: inline-block;
  position: relative;
  width: 280px;
  height: 280px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 136px;
    left: 136px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 272px;
    height: 272px;
    opacity: 0;
  }
}


</style>